<template>
  <div>
    <div class="main-content main-content-contact">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-trail breadcrumbs">
              <ul class="trail-items breadcrumb">
                <li class="trail-item trail-begin">
                  <router-link :to="{ name: 'Home' }"> Principal </router-link>
                </li>
                <li class="trail-item trail-end active">
                  Contactanos
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="content-area content-contact col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="site-main">
              <h3 class="custom_blog_title">Contactanos</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="page-main-content">
        <div class="container">
            <div class="row">
            <div class="col-sm-12">
                <div class="form-contact">
                <div class="col-lg-8 no-padding">
                    <div class="form-message">
                    <h2 class="title">
                        Envianos un mensaje:
                    </h2>
                    <form action="#" class="tanajil-contact-fom">
                        <div class="row">
                        <div class="col-sm-6">
                            <p>
                            <span class="form-label">Tu nombre *</span>
                            <span class="form-control-wrap your-name">
                                <input
                                title="your-name"
                                type="text"
                                name="your-name"
                                size="40"
                                class="form-control form-control-name"
                                />
                            </span>
                            </p>
                        </div>
                        <div class="col-sm-6">
                            <p>
                            <span class="form-label">
                                Tu email *
                            </span>
                            <span class="form-control-wrap your-email">
                                <input
                                title="your-email"
                                type="email"
                                name="your-email"
                                size="40"
                                class="form-control form-control-email"
                                />
                            </span>
                            </p>
                        </div>
                        </div>
                        <div class="row">
                        <div class="col-sm-6">
                            <p>
                            <span class="form-label">Telefono</span>
                            <span class="form-control-wrap your-phone">
                                <input
                                title="your-phone"
                                type="text"
                                name="your-phone"
                                class="form-control form-control-phone"
                                />
                            </span>
                            </p>
                        </div>
                        <div class="col-sm-6">
                            <p>
                            <span class="form-label">
                                Empresa
                            </span>
                            <span class="form-control-wrap your-company">
                                <input
                                title="your-company"
                                type="text"
                                name="your-company"
                                class="form-control your-company"
                                />
                            </span>
                            </p>
                        </div>
                        </div>
                        <p>
                        <span class="form-label">
                            Tu mensaje
                        </span>
                        <span class="wpcf7-form-control-wrap your-message">
                            <textarea
                            title="your-message"
                            name="your-message"
                            cols="40"
                            rows="9"
                            class="form-control your-textarea"
                            ></textarea>
                        </span>
                        </p>
                        <p>
                        <input
                            type="submit"
                            value="ENVIAR MENSAJE"
                            class="form-control-submit button-submit"
                            @click.prevent="sendEmail()"
                        />
                        </p>
                    </form>
                    </div>
                </div>
                <div class="col-lg-4 no-padding">
                    <div class="form-contact-information">
                    <form action="#" class="tanajil-contact-info">
                        <h2 class="title">
                        Informacion de contacto
                        </h2>
                        <div class="info">
                        <div class="item address">
                            <span class="icon"> </span>
                            <span class="text">
                                {{contact.address1}}
                            </span>
                            <span class="text">
                                {{contact.address2}}
                            </span>
                        </div>
                        <div class="item phone">
                            <span class="icon"> </span>
                            <span class="text">
                                {{contact.phone1}}
                            </span>
                            <span class="text">
                                {{contact.phone2}}
                            </span>
                        </div>
                        <div class="item email">
                            <span class="icon"> </span>
                            <span class="text">
                            {{contact.email}}
                            </span>
                        </div>
                        </div>
                        <div class="socials">
                            <a v-if="(networks !== undefined) ? (networks.facebook!=null) : false" :href="(networks !== undefined) ? networks.facebook : ''" class="social-item" target="_blank">
                                <span class="icon fa fa-facebook"> </span>
                            </a>
                            <a v-if="(networks !== undefined) ? (networks.twitter!=null) : false" :href="(networks !== undefined) ? networks.twitter : ''" class="social-item" target="_blank">
                                <span class="icon fa fa-twitter-square"> </span>
                            </a>
                            <a v-if="(networks !== undefined) ? (networks.instagram!=null) : false" :href="(networks !== undefined) ? networks.instagram : ''" class="social-item" target="_blank">
                                <span class="icon fa fa-instagram"> </span>
                            </a>
                            <a v-if="(networks !== undefined) ? (networks.whatsapp!=null) : false" :href="(networks !== undefined) ? ('https://wa.me/54' + networks.whatsapp) : ''" class="social-item" target="_blank">
                                <span class="icon fa fa-whatsapp"> </span>
                            </a>
                            <a v-if="(networks !== undefined) ? (networks.telegram!=null) : false" :href="(networks !== undefined) ? networks.telegram : ''" class="social-item" target="_blank">
                                <span class="icon fa fa-telegram"> </span>
                            </a>
                        </div>
                    </form>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
        name: '',
        email: '',
        phone:'',
        company:'',
        message: ''
    }
  },
  computed: {
    ...mapGetters({
      contact: "ecommerce/contact",
      networks: "ecommerce/networks"
    })
  },
  methods: {
    sendEmail() {

    },
    resetForm(){
        this.name = ''
        this.email = ''
        this.phone=''
        this.company=''
        this.message = ''
    }
  }
};
</script>

<style></style>
